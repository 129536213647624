<!--
 * @Description: 批量购买
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 17:01:20
 * @LastEditors: zmj
 * @LastEditTime: 2022-03-21 11:19:26
-->
<template>
  <div>
    <div>
      <div class="w ww piliang">
        <div class="bread">
          {{this.$t('position')}} > <a @click="goIndex">{{this.$t('homePage')}}</a> > <a>{{this.$t('batch')}}</a>
        </div>
        <h2>{{this.$t('batch')}}</h2>
        <div class="piliang_top">
          <div class="piliang_top_1">
            <img src="@/assets/img/66.png" width="100%" />
          </div>
          <h3>{{this.$t('buy')}}</h3>
          <p>
           {{this.$t('select')}}
          </p>
          <p>
            {{this.$t('note')}}
          </p>
        </div>
        <div class="piliang_con">
          <div>
            <div class="fl piliang_con_1">
              <label class="btn" @click="changeType(1)" for="upfile"
                >{{this.$t('BatchUpload')}}</label
              >
              <input
                type="file"
                id="upfile"
                ref="clearFile"
                @change="getFile($event)"
                accept=".xlsx"
              />
              <a @click="changeType(2)">{{this.$t('CopyPaste')}}</a>
            </div>
            <div class="fl piliang_con_2">
              <a :href="tempUrl">{{this.$t('uploadAndDownload')}}</a>
            </div>
            <div class="clear"></div>
          </div>

          <div class="piliang_con_ta" v-show="type == 1">
            <table width="100%">
              <tr>
                <th>{{this.$t('no')}}</th>
                <th>{{this.$t('goods')}}</th>
                <th>{{this.$t('quantity')}}</th>
                <th>{{this.$t('unitPrice')}}</th>
                <th>{{this.$t('totalCount')}}</th>
              </tr>

              <tr v-for="(v, i) in listData" :key="i">
                <td>
                  <input
                    name=""
                    type="text"
                    @focus="getBfDate(v.specsCode)"
                    @blur="handInputBlur(i)"
                    v-model="v.specsCode"
                    :placeholder="this.$t('iptContent')"
                    class="piliang_con_ta_1"
                  />
                </td>
                <td>{{ v.productName }}</td>
                <td>
                  <div class="gw_num">
                    <em class="jian" @click="deIncrease(v, i, 'de')">-</em>
                    <input
                      type="number"
                      @focus="getBfDate('')"
                      @blur="proNumBlur(i)"
                      v-model="v.quantity"
                      class="num"
                    />
                    <em class="add" @click="deIncrease(v, i, 'add')">+</em>
                  </div>
                </td>
                <td>{{ v.specsPrice || 0 }}</td>
                <td>{{ v.specsTotalPrice || 0 }}</td>
              </tr>

              <tr>
                <td>
                  <input
                    name=""
                    @blur="handInputBlur('newAdd')"
                    type="text"
                    v-model="handInputPro"
                    :placeholder="this.$t('iptContent')"
                    class="piliang_con_ta_1"
                  />
                </td>
                <td>{{this.$t('BuildName')}}</td>
                <td>
                  <div class="gw_num">
                    <em class="jian" @click="deIncrsNull">-</em>
                    <input type="number" value="1" class="num" />
                    <em class="add" @click="deIncrsNull">+</em>
                  </div>
                </td>
                <td>{{this.$t('unitPrice')}}</td>
                <td>{{this.$t('totalPrice')}}</td>
              </tr>
            </table>
          </div>

          <div v-show="type == 2">
            <div class="shenqing_3">
              <textarea
                name=""
                cols="12"
                rows="10"
                v-model="areaTxt"
                placeholder="将货号和数量分开一个空格，不同的货号之间逗号或回车键分隔
Sample：RB-001 2,RB-002 1,RB-003 3"
              ></textarea>
            </div>
          </div>

          <div class="piliang_con_ta_2"><a @click="goBuy">{{this.$t('OrderPurchase')}}</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  dealUrlHttp,
  goIndex,
  showLayer,
  getFile,
  isHasLogin,
  ifCountZero,
} from "@/utils/com";
import { batchBuy, addByHand, addCartBatch, copy2Buy } from "./model";
export default {
  name: "BulkBuy",
  data() {
    return {
      tempUrl: dealUrlHttp(
        "template/%E6%89%B9%E9%87%8F%E8%B4%AD%E6%A8%A1%E6%9D%BF.xlsx"
      ),
      upFile: "",
      type: 1, //1是批量服务上传 2：复制剪贴
      listData: [],
      handInputPro: "", //手动输入的产品编号
      beforeVal: "", //输入框查询前的值
      areaTxt: "",
    };
  },
  methods: {
    //下单购买
    goBuy() {
      if (isHasLogin()) {
        if (this.type == 1 && !ifCountZero(this.listData)) {
          showLayer("toast", "请至少选择一件");
          return;
        }
        this.type == 1 && this.addCartApi();
        this.type == 2 && this.copy2BuyApi();
      } else {
        showLayer("toast", "请先登录");
      }
    },

    //复制黏贴购买
    async copy2BuyApi() {
      !this.areaTxt && showLayer("toast", "请按要求输入内容");
      if (this.areaTxt) {
        let res = await copy2Buy({ content: this.areaTxt });
        if (Array.isArray(res)) {
          this.listData = res;
          this.addCartApi();
        } else {
          showLayer("toast", "未找到商品");
        }
      }
    },

    async addCartApi() {
      let params = [];
      let obj = {};
      //遍历规格
      this.listData.forEach((v) => {
        if (v.quantity > 0) {
          obj = {
            productRefOwid: v.productRefOwid,
            productPic: v.productPic || "",
            categoryRefOwid: v.categoryRefOwid, //商品分类
            categoryName: v.categoryName || "",
            productName: v.productName || "",
            secondName: v.secondName || "",
            itemQuantity: v.quantity,
            itemPrice: v.specsPrice,
            specsRefOwid: v.specsRefOwid,
            specsName: v.specsName,
          };
          params.push({ ...obj });
        }
      });

      //批量加入购物车
      let res = await addCartBatch({ productList: params });
      if (res) {
        this.$router.push("/profile/shoppingcart");
      }
    },

    //暂存输入框输入之前的值
    getBfDate(value) {
      this.beforeVal = value;
    },

    //手动输入
    async handInputBlur(index) {
      let code = this.handInputPro;
      let curPro = {};
      let res;
      if (index != "newAdd") {
        //修改已经存在的商品
        curPro = this.listData[index];
        code = curPro.specsCode;
        if (this.beforeVal == code) {
          return;
        }
      }
      code && (res = await addByHand({ specsCode: code }));
      if (res && res.owid) {
        let prize = res.specsPrice;
        res.quantity = 1;
        res.specsTotalPrice = prize;
        if (index != "newAdd") {
          this.$set(this.listData, index, res);
        } else {
          //这是手动输入的值
          this.handInputPro && this.listData.push(res);
          //重新清空
          this.handInputPro = "";
        }
      } else {
        if (index != "newAdd") {
          this.listData[index].specsCode = this.beforeVal;
        }
        showLayer("toast", "不存在该货号");
      }
    },

    //加减商品
    deIncrease(v, index, type) {
      let { quantity = 0, specsStock = 0, specsPrice } = this.listData[index];
      let newNum = quantity;
      //增加
      switch (type) {
        case "add":
          quantity < specsStock + 1
            ? newNum++
            : showLayer("toast", "不能再多了");
          break;
        case "de":
          quantity > 0 ? newNum-- : showLayer("toast", "不能再少了");
          break;
      }
      this.listData[index].quantity = newNum;
      this.listData[index].specsTotalPrice = (specsPrice * newNum).toFixed(2);
    },

    deIncrsNull() {
      showLayer("toast", "请先填写货号");
    },

    //文件上传
    async getFile(event) {
      this.upFile = getFile(event)[0];
      console.log(this.upFile);
      if (this.upFile) {
        let res = await batchBuy({
          file: this.upFile,
          data: JSON.stringify({ fileClass: "oasis" }),
        });
        res && (this.listData = res || []);
      }
    },

    //填写商品失去焦点 临界值处理
    proNumBlur(index) {
      let { quantity = 0, specsStock = 0 } = this.listData[index];
      if (quantity > specsStock) {
        this.listData[index].quantity = specsStock;
        showLayer("toast", "超出库存");
      }
      if (quantity < 0 || !quantity) {
        this.listData[index].quantity = 0;
      }
    },

    //点击切换类型
    changeType(type) {
      this.type = type;
    },

    //点击返回首页
    goIndex() {
      goIndex(this);
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
#upfile {
  display: none;
}
.shenqing_3 textarea {
  width: 100%;
  height: auto;
}
</style>
