/*
 * @Description: 批量购
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-19 11:31:14
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-21 15:36:44
 */
import {
  Product,
  Com
} from '@/api/index';
import {
  dealUrlHttp,
  showLayer,
  isHasLogin
} from '@/utils/com';

//点击按钮 批量购买上传
export async function batchBuy(data) {
  let res = await Com.upFileCom(data);
  if (res && res.filePath) {
    let result = await Product.batchBuy({
      filePath: res.filePath
    });
    return result;
  }
}

//手动输入
export async function addByHand(data) {
  let res = await Product.addProByHand(data);
  return res
}

// 批量加入购物车
export async function addCartBatch(data) {
  let res = await Product.addCartBatch(data)
  return res;
}

// 批量黏贴
export async function copy2Buy(data) {
  let res = await Product.copy2Buy(data)
  return res;
}